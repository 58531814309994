import {convertUnix, convertUnixDate, convertUnixToLocalHour} from "./convert-unix";
import { getStartOfDay } from "./get-start-of-day";
import { shipmentQuantityKeys, unscheduledVendorName } from "../components/Constants"


function parseShipments(gsfShipmentItemSummaries, ocrVendors=[], time_zone, scipData=[], tsiForecastData=[], regionId) {
  const {
    vendorConfirmedUnits,
    vendorReceivedUnits,
    transConfirmedUnits,
    transReceivedUnits,
  } = shipmentQuantityKeys
  let timeZoneIncluded = true
  if (!time_zone) {
    time_zone = "UTC";
    timeZoneIncluded = false
  }
  if (!gsfShipmentItemSummaries) {
    return { parsedIncomingShipments: [], parsedDockReceived: [] }
  }
  let incomingDockReceived = [];
  let incomingIncomingShipments = [];
  let incomingSimpleDockReceived = [];
  for (const gsfShipmentItemSummary of gsfShipmentItemSummaries) {
    // Flatten out object to an array for easier processing
    const associatedAppointments = gsfShipmentItemSummary.associatedAppointments ?
        Object.entries(JSON.parse(gsfShipmentItemSummary.associatedAppointments))
            .map(([appointmentId, appointmentDetails]) => appointmentDetails)
        : []
    ;
    // Sort appointments
    const appointmentDetail = associatedAppointments.sort((a, b) => {
      if (a.scheduledArrivalTime < b.scheduledArrivalTime) {
        return -1
      }
      if (a.scheduledArrivalTime > b.scheduledArrivalTime) {
        return 1
      }
      return 0
    });

    // Sort dock receives to ensure correct order
    const dockReceiveHistory = gsfShipmentItemSummary.dockReceiveHistory ?
        JSON.parse(gsfShipmentItemSummary.dockReceiveHistory).sort((a, b) => {

          if (a.dockReceiveTime < b.dockReceiveTime) {
            return -1
          }
          if (a.dockReceiveTime > b.dockReceiveTime) {
            return 1
          }
          return 0
        })
        : [];

    // May need to get more complex for this check, but we'll keep it like this for now
    if (!gsfShipmentItemSummary.firstDockReceiveTime
        && gsfShipmentItemSummary.firstAppointmentTime >= getStartOfDay(time_zone, -1)
            && gsfShipmentItemSummary.firstAppointmentTime < getStartOfDay(time_zone, 5)
        // New SCIP model is region 1 only for now
        && (regionId !== 1
            // Need appointment or 10-4
            || (regionId === 1 && (appointmentDetail.length > 0 || gsfShipmentItemSummary.freightEstimatedArrivalTime)))
    ){
    // Incoming Shipments
        const loadType = gsfShipmentItemSummary.orderId.startsWith("PO_") ? "Vendor" : "Transship";
        
        if (gsfShipmentItemSummary.poItemSummary!==null) {
          // take scip summary if exists otherwise use confirmed qty if completely confirmed or use submitted
          let incomingItemSummary = {"UNKNOWN": 0};
          let expectedUnitSource = 'UNKNOWN';
          if (regionId !== 1 && loadType === 'Vendor' && gsfShipmentItemSummary.expectedQuantityScip) {
            incomingItemSummary = JSON.parse(gsfShipmentItemSummary.expectedQuantityScip);
            expectedUnitSource = gsfShipmentItemSummary.expectedQtySourceScip
          } else if (gsfShipmentItemSummary.poItemSummary) {
            if (JSON.parse(gsfShipmentItemSummary.poItemSummary).summaryByTempZoneDetailed &&
                Object.keys(JSON.parse(gsfShipmentItemSummary.poItemSummary).summaryByTempZoneDetailed).length > 0) {
              if (loadType === 'Vendor') {
                incomingItemSummary = Object.entries(JSON.parse(gsfShipmentItemSummary.poItemSummary).summaryByTempZoneDetailed)
                    .map(([tempZone, quantities]) => ({[tempZone]: quantities.quantityConfirmed > 0 ? quantities.quantityConfirmed : quantities.quantitySubmitted}))
                    .reduce((temp, quantity) =>{return Object.assign(temp, quantity)});
                expectedUnitSource =  gsfShipmentItemSummary.orderCondition === "CompletelyConfirmed" ? 'PO Confirmed' : 'PO Submitted';
              } else {
                // SCIP predicts TSI for the simple temp zones. No Protein or Produce
                incomingItemSummary = Object.entries(JSON.parse(gsfShipmentItemSummary.poItemSummary).summaryByTempZone)
                    .map(([tempZone, quantities]) => ({[tempZone]: quantities[transConfirmedUnits] > 0 ? quantities[transConfirmedUnits] : quantities.wmsQuantityReceived}))
                    .reduce((temp, quantity) =>{return Object.assign(temp, quantity)});
                expectedUnitSource = 'Manifest';
              }
            }
          }

          let scheduledArrivals = appointmentDetail.map(appointment => appointment.scheduledArrivalTime);
          // Default to scip if we don't have appointments
          if (scheduledArrivals.length === 0) {
            scheduledArrivals = [gsfShipmentItemSummary.firstAppointmentTime]
          }

          if (Math.max(...scheduledArrivals) > timeZoneIncluded ? getStartOfDay(time_zone, -1) : Date.now() - 24 * 60 * 60 * 1000) {

            //  PO Temp Zone loop
            for (const [tempZone, tempZoneUnits] of Object.entries(incomingItemSummary)) {
              // Deep copy, so we can push to the appropriate array without affecting the other temp zones
              let tempZoneShipment = JSON.parse(JSON.stringify(gsfShipmentItemSummary));
              // expected columns - need to make sure these exist otherwise csv and sort logic will not work
              // loadType, source, loadId, tempZone, status, scheduledArrivals, expectedArrivalTime, arrivalDatetime,
              // expectedUnits, expectedPallets, shipmentId, ocrVendor, scac
              tempZoneShipment.loadType = loadType;
              tempZoneShipment.source = loadType === "Vendor" ? tempZoneShipment.distributorId : tempZoneShipment.srcWarehouseId;
              tempZoneShipment.loadId = tempZoneShipment.orderId.substring(tempZoneShipment.orderId.indexOf('_') + 1);
              tempZoneShipment.tempZone = tempZone;
              tempZoneShipment.id = `${tempZoneShipment.loadId}-${tempZoneShipment.tempZone}`
              tempZoneShipment.status = [gsfShipmentItemSummary.freightArrivalStatus] !== null ? [gsfShipmentItemSummary.freightArrivalStatus] : [undefined];
              tempZoneShipment.scheduledArrivals = scheduledArrivals;
              tempZoneShipment.scheduledArrivalsParsed = tempZoneShipment.scheduledArrivals.map(data => convertUnix(data, time_zone, "sv-SE"));
              tempZoneShipment.expectedArrivalTime = [gsfShipmentItemSummary.freightEstimatedArrivalTime] !== null ? [gsfShipmentItemSummary.freightEstimatedArrivalTime] : [undefined];
              tempZoneShipment.expectedArrivalTimeSource = tempZoneShipment.expectedArrivalTime  !== [undefined] ? ["10-4"] : [undefined];
              tempZoneShipment.expectedArrivalTimeParsed = tempZoneShipment.expectedArrivalTime ? tempZoneShipment.expectedArrivalTime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
              tempZoneShipment.arrivalDatetime = [gsfShipmentItemSummary.freightActualArrivalTime] !== null ? [gsfShipmentItemSummary.freightActualArrivalTime]: [undefined];
              tempZoneShipment.arrivalDatetimeSource = tempZoneShipment.arrivalDatetime  !== [undefined] ? ["10-4"] : [undefined];
              tempZoneShipment.arrivalDatetimeParsed = tempZoneShipment.arrivalDatetime ? tempZoneShipment.arrivalDatetime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
              tempZoneShipment.expectedUnits = tempZoneUnits;              
              tempZoneShipment.expectedPallets = Math.ceil(tempZoneUnits * .1 / 40);

              // Get pallet count from manifest and SHREK tracking
              if (loadType === 'Transship') {
                // Bug with missing vrid on freshly manifested TSI
                if (!gsfShipmentItemSummary.vehicleRunId) {
                  continue;
                }
                const manifestSummary = gsfShipmentItemSummary.manifestSummary ? JSON.parse(gsfShipmentItemSummary.manifestSummary) : {};
                const palletSummary = manifestSummary.palletSummary ? manifestSummary.palletSummary : {};
                let expectedPallets = 0;

                for (const key of Object.keys(palletSummary)) {
                  const palletDetails = palletSummary[key];
                  if (palletDetails) {
                    // This may overstate our pallets if there's mixed pallets
                    // Swap to palletDetails.tempZone === tempZone and uncomment the else if below if we want to change
                    if (palletDetails.tempZone.includes(tempZone)) {
                      expectedPallets += 1;
                      // Let's only include this if we want to count split pallets as half
                      // } else if (palletDetails.tempZone.includes(tempZone)) {
                      //   expectedPallets += .5;
                      // }
                    }
                  }
                }
                tempZoneShipment.expectedPallets = expectedPallets;

                let shrekData = tsiForecastData.filter((tsiForecast) => tsiForecast.vehicleRunId === tempZoneShipment.vehicleRunId);
                if (shrekData.length > 0) {
                  shrekData = shrekData[0];
                }

                tempZoneShipment.expectedArrivalTime = tempZoneShipment.vrEstimatedTimeOfArrival ? [tempZoneShipment.vrEstimatedTimeOfArrival] : shrekData.vrPlannedCheckinTime ? [shrekData.vrPlannedCheckinTime] : [undefined];
                tempZoneShipment.expectedArrivalTimeSource = tempZoneShipment.vrEstimatedTimeOfArrival ? ['ETAPrediktion'] : shrekData.vrPlannedCheckinTime ? ["SHREK"] : [undefined];
                tempZoneShipment.arrivalDatetime = shrekData.vrCheckinCompletionTime ? [shrekData.vrCheckinCompletionTime] : [undefined];
                tempZoneShipment.arrivalDatetimeSource = shrekData.vrCheckinCompletionTime ? ["SHREK"] : [undefined];
              }

              // TODO for vendor
              tempZoneShipment.shipmentId = loadType === 'Vendor' ? [] : [tempZoneShipment.vehicleRunId];
              tempZoneShipment.ocrVendor =
                  ocrVendors.filter(ocrVendor => ocrVendor.distributorId === tempZoneShipment.source).length > 0 ?
                      "Y" : "N";
              tempZoneShipment.scac = appointmentDetail.map(appointment => appointment.carrierId);

              tempZoneShipment.timeZone = time_zone;
              tempZoneShipment.expectedUnitSource = expectedUnitSource;

              tempZoneShipment.criticalAsinEvents = tempZoneShipment.eventSummary ?
                  Object.values(JSON.parse(tempZoneShipment.eventSummary))
                      // Following MMv1 logic. Show if event start is at most 2 weeks out and hasn't already ended
                      .filter(criticalEvent => (criticalEvent.startDate >= (Date.now() - (14 * 24 * 60 * 60 * 1000)) && criticalEvent.endDate >= Date.now()) || (criticalEvent.startDate <= (Date.now()) && criticalEvent.endDate >= Date.now()))
                  : []
              let tempZoneUnitsDetailed = JSON.parse(tempZoneShipment.poItemSummary).summaryByTempZoneDetailed;
              tempZoneShipment.asrsConfirmedUnits = loadType === "Vendor" ? (tempZoneUnitsDetailed[tempZone]?.asrsQuantityConfirmed)??0 : tempZoneUnitsDetailed[tempZone]?.asrsMainifestQuantity??0;
              tempZoneShipment.confirmedUnits = loadType === "Vendor" ?
                  tempZoneUnitsDetailed[tempZone]?.quantityConfirmed??0: tempZoneUnitsDetailed[tempZone]?.manifestQuantity??tempZoneUnitsDetailed[tempZone]?.wmsQuantityReceived;
              tempZoneShipment.expectedAsrsUnits = Math.round((tempZoneShipment.asrsConfirmedUnits / tempZoneShipment.confirmedUnits)*tempZoneShipment.expectedUnits);
              tempZoneShipment.expectedAsrsUnitsPercent = Math.round(((tempZoneShipment.asrsConfirmedUnits / tempZoneShipment.confirmedUnits) * 100)*100)/100 ;
              // Sometimes SCIP predicts 0. Let's just not show that
              if (tempZoneShipment.expectedUnits > 0) {
                // This is for debugging
                tempZoneShipment.logicSource = 'Incoming';
                incomingIncomingShipments.push(tempZoneShipment);
              }
            }
          }
        } 
    }

    if (gsfShipmentItemSummary.firstDockReceiveTime) {
      // Dock Received
      //  Temp Zone loop
      const poItemSummary = gsfShipmentItemSummary.poItemSummary ?
          JSON.parse(gsfShipmentItemSummary.poItemSummary)
          : {};
      const summaryByTempZoneDetailed = poItemSummary.summaryByTempZoneDetailed ?
          poItemSummary.summaryByTempZoneDetailed
          : {};
     
      for (const [tempZone, tempZoneUnits] of Object.entries(summaryByTempZoneDetailed)) {
        // Deep copy, so we can push to the appropriate array without affecting the other temp zones
        let tempZoneShipment = JSON.parse(JSON.stringify(gsfShipmentItemSummary));
        // Table Columns:
        // loadType, source, loadId, tempZone, actualArrival, dockReceived, lastDockReceiveBy, firstReceiveTime
        // confirmedUnits, receivedUnits, nyrnys, progress, dwellDays, timeToSLA,
        const loadType = tempZoneShipment.orderId.startsWith("PO_") ? "Vendor" : "Transship";
        tempZoneShipment.loadType = loadType;
        tempZoneShipment.source = loadType === "Vendor" ? tempZoneShipment.distributorId : tempZoneShipment.srcWarehouseId;
        tempZoneShipment.loadId = tempZoneShipment.orderId.substring(tempZoneShipment.orderId.indexOf('_') + 1);
        tempZoneShipment.tempZone = tempZone;
        tempZoneShipment.actualArrival = loadType === "Vendor" ?
        //uncomment once 10-4 data is ready for prod
        tempZoneShipment.freightActualArrivalTime ? tempZoneShipment.freightActualArrivalTime : 
            appointmentDetail[0] !== undefined ? appointmentDetail[0].arrivalTime : null
            : tempZoneShipment.vrCheckinCompletionTime;
        // This is for the csv
        tempZoneShipment.actualArrivalParsed = convertUnix(tempZoneShipment.actualArrival, time_zone, "sv-SE");
        tempZoneShipment.dockReceived = tempZoneShipment.firstDockReceiveTime;
        // This is for the csv
        tempZoneShipment.dockReceivedParsed = convertUnix(tempZoneShipment.dockReceived, time_zone, "sv-SE");
        tempZoneShipment.lastDockReceiveBy = tempZoneShipment.firstDockReceiveBy;
        // This is for the csv
        tempZoneShipment.firstReceiveTimeParsed = convertUnix(tempZoneShipment.firstReceiveTime, time_zone, "sv-SE");
        // TODO: Let's simplify this after a couple weeks when manifests should be in all shipments
        tempZoneShipment.confirmedUnits = loadType === "Vendor" ?
            tempZoneUnits[vendorConfirmedUnits]
            : tempZoneUnits[transConfirmedUnits] ?
                tempZoneUnits[transConfirmedUnits]
                : tempZoneUnits.wmsQuantityReceived;
        tempZoneShipment.receivedUnits = loadType === "Vendor" ? tempZoneUnits[vendorReceivedUnits] : tempZoneUnits[transReceivedUnits];
        tempZoneShipment.nyrnys = tempZoneShipment.confirmedUnits - tempZoneShipment.receivedUnits;
        tempZoneShipment.progress = Math.round(tempZoneShipment.receivedUnits / tempZoneShipment.confirmedUnits * 10000) / 100;
        tempZoneShipment.dwellDays = Math.floor((Date.now() - tempZoneShipment.dockReceived) / (24 * 60 * 60 * 1000) * 100) / 100;
        tempZoneShipment.sla = loadType === "Vendor" ? tempZoneUnits.vendorReceiveSlaFcs : tempZoneUnits.tshipSla;
        tempZoneShipment.asinCube = tempZoneUnits.asinCube;
        
        tempZoneShipment.asrsConfirmedUnits = loadType === "Vendor" ? (tempZoneUnits.asrsQuantityConfirmed ?
          tempZoneUnits.asrsQuantityConfirmed : 0) : (tempZoneUnits.asrsMainifestQuantity ? tempZoneUnits.asrsMainifestQuantity : 0 );
        tempZoneShipment.asrsReceivedUnits = loadType === "Vendor" ? (tempZoneUnits.asrsQuantityReceived ?
          tempZoneUnits.asrsQuantityReceived : 0) : (tempZoneUnits.asrsQuantityReceivedWmsStow ? tempZoneUnits.asrsQuantityReceivedWmsStow: 0 );
        tempZoneShipment.asrsConfirmedUnitsPercent = Math.round(((tempZoneShipment.asrsConfirmedUnits/tempZoneShipment.confirmedUnits)*100)*100)/100;
        // Automatic Suppression if 97% received and 24 hours past dock receive
        // tempZoneShipment.suppression = (tempZoneShipment.receivedUnits / tempZoneShipment.confirmedUnits >= 0.97) && (Date.now()>=tempZoneShipment.dockReceived+24*60*60*1000);
        if(tempZoneShipment.suppressionHistory){
         
          const suppressionHistory = tempZoneShipment.suppressionHistory ? JSON.parse(tempZoneShipment.suppressionHistory) : [];
          const tempZoneSuppressions = suppressionHistory.filter(
              suppression => suppression.tempZone === tempZone
          ).sort(
              (a,b) => b.suppressionTime - a.suppressionTime
          )
          if (tempZoneSuppressions.length > 0) {
            const tempZoneSuppression = tempZoneSuppressions[0];
            tempZoneShipment.suppression = tempZoneSuppression.suppressed ? tempZoneSuppression.suppressed : false;
          } else {
            tempZoneShipment.suppression = false;
          }
        }
        else {
          tempZoneShipment.suppression = (tempZoneShipment.receivedUnits / tempZoneShipment.confirmedUnits >= 0.97) && (Date.now()>=tempZoneShipment.dockReceived+24*60*60*1000);
        
        }
        tempZoneShipment.timeZone = time_zone;
        // Pallet Review Data
        if (loadType === "Transship") {

          const manifestSummary = gsfShipmentItemSummary.manifestSummary ? JSON.parse(gsfShipmentItemSummary.manifestSummary) : {};
          const palletSummary = manifestSummary.palletSummary ? manifestSummary.palletSummary : {};
          const receivedPallets = gsfShipmentItemSummary.palletIds ? JSON.parse(gsfShipmentItemSummary.palletIds) : [];
          for (const palletId of receivedPallets) {
            const pallet = palletSummary[palletId] ? palletSummary[palletId] : {};
            pallet.received = true;
            palletSummary[palletId] = pallet;
          }
          tempZoneShipment.palletSummary = Object.entries(palletSummary).map(([palletId, palletData]) => {
            palletData.palletId = palletId;
            return palletData
          });
        }

        tempZoneShipment.criticalAsinEvents = tempZoneShipment.eventSummary ?
            Object.values(JSON.parse(tempZoneShipment.eventSummary))
                // Following MMv1 logic. Show if event start is at most 2 weeks out and hasn't already ended
                .filter(criticalEvent => (criticalEvent.startDate >= (Date.now() - (14 * 24 * 60 * 60 * 1000)) && criticalEvent.endDate >= Date.now()) || (criticalEvent.startDate <= (Date.now()) && criticalEvent.endDate >= Date.now()))
            : []

        // Corner case where we have 0 confirmed units but the units were ordered
        if (loadType === "Vendor" && tempZoneShipment.confirmedUnits <= 0) {
        } else {
          tempZoneShipment.timeZone = time_zone;

          // This is for debugging
          tempZoneShipment.logicSource = 'Dock Received';
          incomingDockReceived.push(tempZoneShipment);
        }

      }
      
      // NYR Calculations need just the three basic temp zones
      const summaryByTempZone = poItemSummary.summaryByTempZone ?
          poItemSummary.summaryByTempZone
          : {};
      for (const [tempZone, tempZoneUnits] of Object.entries(summaryByTempZone)) {
        // Deep copy, so we can push to the appropriate array without affecting the other temp zones
        let tempZoneShipment = JSON.parse(JSON.stringify(gsfShipmentItemSummary));
        tempZoneShipment.tempZone = tempZone;
        tempZoneShipment.asinCube = tempZoneUnits.asinCube;
        const loadType = tempZoneShipment.orderId.startsWith("PO_") ? "Vendor" : "Transship";
        tempZoneShipment.loadType = loadType;
        tempZoneShipment.sla = loadType === "Vendor" ? tempZoneUnits.vendorReceiveSlaFcs : tempZoneUnits.tshipSla;
        tempZoneShipment.source = loadType === "Vendor" ? tempZoneShipment.distributorId : tempZoneShipment.srcWarehouseId;
        tempZoneShipment.loadId = tempZoneShipment.orderId.substring(tempZoneShipment.orderId.indexOf('_') + 1);
        // TODO: Let's simplify this after a couple weeks when manifests should be in all shipments
        tempZoneShipment.confirmedUnits = loadType === "Vendor" ?
            tempZoneUnits[vendorConfirmedUnits]
            : tempZoneUnits[transConfirmedUnits] ?
                tempZoneUnits[transConfirmedUnits]
                : tempZoneUnits.wmsQuantityReceived;
        tempZoneShipment.receivedUnits = loadType === "Vendor" ? tempZoneUnits[vendorReceivedUnits] : tempZoneUnits[transReceivedUnits];
        tempZoneShipment.actualArrival = appointmentDetail[0] !== undefined ? appointmentDetail[0].arrivalTime : null;
        tempZoneShipment.dockReceived = dockReceiveHistory.length > 0 ? dockReceiveHistory[dockReceiveHistory.length - 1].dockReceiveTime : undefined;
        // Automatic Suppression if 100% received
        tempZoneShipment.suppression = tempZoneShipment.receivedUnits / tempZoneShipment.confirmedUnits >= 1 ?
            true :
            tempZoneShipment.suppression ?
                tempZoneShipment.suppression
                : false;

        tempZoneShipment.criticalAsinEvents = tempZoneShipment.eventSummary ?
            Object.values(JSON.parse(tempZoneShipment.eventSummary))
                // Following MMv1 logic. Show if event start is at most 2 weeks out and hasn't already ended
                .filter(criticalEvent => (criticalEvent.startDate >= (Date.now() - (14 * 24 * 60 * 60 * 1000)) && criticalEvent.endDate >= Date.now()) || (criticalEvent.startDate <= (Date.now()) && criticalEvent.endDate >= Date.now()))
            : []
        tempZoneShipment.timeZone = time_zone;

        // This is for debugging
        tempZoneShipment.logicSource = 'Dock Received';
        incomingSimpleDockReceived.push(tempZoneShipment);
      }
    }
  }

  const tsiForecasts = scipData.filter((scipItem) => scipItem.ib_flow === 'TSI');
  const manifestedIncomingTSIs = incomingIncomingShipments.filter((incomingShipment) => incomingShipment.loadType === 'Transship');
  const dockReceivedTSIs = incomingDockReceived.filter((incomingDockReceived) => incomingDockReceived.loadType === 'Transship');

  for (const tsiForecast of tsiForecasts) {
    const allVrids = tsiForecast.load_id.split(', ');
    if (allVrids.length === 0) {
      continue;
    }

    const remainingVrids = allVrids.filter((vrid) =>
        manifestedIncomingTSIs.filter((manifestedIncomingTSI) => manifestedIncomingTSI.vehicleRunId === vrid).length === 0
        && dockReceivedTSIs.filter((dockReceivedTSI) => dockReceivedTSI.vehicleRunId === vrid).length === 0
    );

    // This means all vrids have a manifest
    if (remainingVrids.length <= 0) {
      continue;
    }

    const tempZone = tsiForecast.temp_zone;
    const manifestedIncomingVrids = manifestedIncomingTSIs.filter((manifestedIncomingTSI) => allVrids.includes(manifestedIncomingTSI.vehicleRunId) && manifestedIncomingTSI.tempZone === tempZone);
    const dockReceivedVrids = incomingDockReceived.filter((dockReceivedShipment) => allVrids.includes(dockReceivedShipment.vehicleRunId)  && dockReceivedShipment.tempZone === tempZone);

    let tempZoneTSIShipment = {};
    tempZoneTSIShipment.tempZone = tempZone;
    tempZoneTSIShipment.loadType = "Transship";
    tempZoneTSIShipment.loadId = "Not Yet Manifested";
    tempZoneTSIShipment.source = tsiForecast.origin_fc;

    // Need to pull out manifested qty
    const incomingManifestQuantity = manifestedIncomingVrids.reduce((sum, filteredIncomingShipments) => sum + filteredIncomingShipments.confirmedUnits, 0);
    const dockReceivedManifestQuantity = dockReceivedVrids.reduce((sum, filteredIncomingShipments) => sum + filteredIncomingShipments.confirmedUnits, 0);
    const totalManifestQuantity = incomingManifestQuantity + dockReceivedManifestQuantity;

    const expectedQuantity = Math.max(tsiForecast.expected_qty - totalManifestQuantity, 0);

    // Could be controversial here but this means we've exceeded our predicted qty
    if (expectedQuantity <= 0) {
      continue;
    }

    tempZoneTSIShipment.expectedUnits = expectedQuantity;

    tempZoneTSIShipment.expectedPallets = Math.ceil(tempZoneTSIShipment.expectedUnits * .1 / 40);
    tempZoneTSIShipment.expectedUnitSource =  "SCIP";
    tempZoneTSIShipment.scheduledArrivals = [tsiForecast.expected_arrival_time];
    tempZoneTSIShipment.firstAppointmentSource = "SCIP";
    tempZoneTSIShipment.scheduledArrivalsParsed = tempZoneTSIShipment.scheduledArrivals.map(data => convertUnix(data, time_zone, "sv-SE"));
    tempZoneTSIShipment.expectedArrivalTime = tsiForecast.expectedArrivalTime ? tsiForecast.expectedArrivalTime : [undefined];
    tempZoneTSIShipment.expectedArrivalTimeSource = tsiForecast.expectedArrivalTimeSource ? tsiForecast.expectedArrivalTimeSource : [undefined];
    tempZoneTSIShipment.expectedArrivalTimeParsed = tempZoneTSIShipment.expectedArrivalTime ? tempZoneTSIShipment.expectedArrivalTime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
    tempZoneTSIShipment.arrivalDatetime = tsiForecast.arrivalDatetime ? tsiForecast.arrivalDatetime : [undefined];
    tempZoneTSIShipment.arrivalDatetimeSource = tsiForecast.arrivalDatetimeSource ? tsiForecast.arrivalDatetimeSource : [undefined];
    tempZoneTSIShipment.arrivalDatetimeParsed = tempZoneTSIShipment.arrivalDatetime ? tempZoneTSIShipment.arrivalDatetime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];

    tempZoneTSIShipment.shipmentId = remainingVrids;
    tempZoneTSIShipment.scac = "";
    tempZoneTSIShipment.ocrVendor = "N";
    tempZoneTSIShipment.timeZone = time_zone;
    tempZoneTSIShipment.transferId = tsiForecast.transferId;

    if (tempZoneTSIShipment.expectedUnits > 0) {
      // This is for debugging
      tempZoneTSIShipment.logicSource = 'TSI Forecasts';
      incomingIncomingShipments.push(tempZoneTSIShipment);
    }
  }

  // Loop FAB SCIP data here as well
  const currentFabForecasts = scipData.filter((scipItem) => scipItem.ib_flow === 'DR' && scipItem.load_id !== 'unscheduledVendor' && scipItem.load_id.includes(' ') && !scipItem.load_id.includes(','));

  for (const fabForecast of currentFabForecasts) {
    let tempZoneFABShipment = JSON.parse(JSON.stringify(fabForecast));
    tempZoneFABShipment.tempZone = fabForecast.temp_zone;
    tempZoneFABShipment.loadType = "Vendor";
    // Need to look into why we do this again
    tempZoneFABShipment.loadId = fabForecast.load_id;
    // Not sure if  we have this data anymore
    tempZoneFABShipment.source = '';
    tempZoneFABShipment.expectedUnits = fabForecast.expected_qty;
    tempZoneFABShipment.expectedPallets = Math.ceil(tempZoneFABShipment.expectedUnits * .1 / 40);
    tempZoneFABShipment.expectedUnitSource = 'FAB';
    tempZoneFABShipment.scheduledArrivals = [fabForecast.expected_arrival_time];
    tempZoneFABShipment.scheduledArrivalsParsed = tempZoneFABShipment.scheduledArrivals.map(data => convertUnix(data, time_zone, "sv-SE"));
    tempZoneFABShipment.expectedArrivalTime = [undefined];
    tempZoneFABShipment.expectedArrivalTimeSource = [undefined];
    tempZoneFABShipment.expectedArrivalTimeParsed = tempZoneFABShipment.expectedArrivalTime ? tempZoneFABShipment.expectedArrivalTime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
    tempZoneFABShipment.arrivalDatetime = [undefined];
    tempZoneFABShipment.arrivalDatetimeSource = [undefined];
    tempZoneFABShipment.arrivalDatetimeParsed = tempZoneFABShipment.arrivalDatetime ? tempZoneFABShipment.arrivalDatetime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
    tempZoneFABShipment.scac = "";
    tempZoneFABShipment.shipmentId = [];
    tempZoneFABShipment.ocrVendor =
        ocrVendors.filter(ocrVendor => ocrVendor.distributorId === tempZoneFABShipment.source).length > 0 ?
            "Y" : "N";
    tempZoneFABShipment.timeZone = time_zone;

    if (tempZoneFABShipment.expectedUnits > 0) {
      // This is for debugging
      tempZoneFABShipment.logicSource = 'FAB Forecasts';
      incomingIncomingShipments.push(tempZoneFABShipment);
    }
  }

  const scipUnscheduledVendors = scipData.filter((scipItem) => scipItem.load_id === 'unscheduledVendor');
  for (const scipItem of scipUnscheduledVendors) {

    const expectedArrivalTime = Number(scipItem.expected_arrival_time);
    const tempZone = scipItem.temp_zone;
    const loadType = 'Vendor';

    const incomingScheduledShipments = incomingIncomingShipments.filter((incomingShipment) => {
      const filteredArrivals = incomingShipment.scheduledArrivals.filter(
          (scheduledArrival) =>
              scheduledArrival >= expectedArrivalTime
              && scheduledArrival < expectedArrivalTime + (24 * 60 * 60 * 1000)
      );
      return (
          (filteredArrivals.length > 0
              || (incomingShipment.freightEstimatedArrivalTime ?
                  incomingShipment.freightEstimatedArrivalTime >= expectedArrivalTime && incomingShipment.freightEstimatedArrivalTime < expectedArrivalTime + (24 * 60 * 60 * 1000)
                  : false))
          && incomingShipment.tempZone === tempZone
          && incomingShipment.loadType === loadType
      );
    })

    const incomingScheduledUnits = incomingScheduledShipments.reduce((sum, filteredIncomingShipments) => sum + filteredIncomingShipments.confirmedUnits, 0);

    const dockReceived = incomingDockReceived.filter(
        (dockReceived) => dockReceived.firstDockReceiveTime >= expectedArrivalTime
            && dockReceived.firstDockReceiveTime < expectedArrivalTime + (24 * 60 * 60 * 1000)
            && dockReceived.tempZone === tempZone
            && dockReceived.loadType === loadType
    )

    const dockReceivedUnits = dockReceived.reduce((sum, filteredDockReceive) => sum + filteredDockReceive.confirmedUnits, 0);

    scipItem.tempZone = scipItem.temp_zone;
    scipItem.loadType = "Vendor";
    scipItem.loadId = unscheduledVendorName;
    scipItem.source = "";
    scipItem.expectedUnits = Math.max(scipItem.expected_qty - incomingScheduledUnits - dockReceivedUnits, 0);
    scipItem.expectedPallets = Math.ceil(scipItem.expectedUnits * .1 / 40);
    scipItem.expectedUnitSource = "SCIP";
    scipItem.scheduledArrivals = [expectedArrivalTime];
    scipItem.scheduledArrivalsParsed = scipItem.scheduledArrivals.map(data => convertUnix(data, time_zone, "sv-SE"));
    scipItem.expectedArrivalTime = [undefined];
    scipItem.expectedArrivalTimeSource = [undefined];
    scipItem.expectedArrivalTimeParsed = scipItem.expectedArrivalTime ? scipItem.expectedArrivalTime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
    scipItem.firstAppointmentSource = 'SCIP';
    scipItem.arrivalDatetime = [undefined];
    scipItem.arrivalDatetimeSource = [undefined];
    scipItem.arrivalDatetimeParsed = scipItem.arrivalDatetime ? scipItem.arrivalDatetime.map(arrival => arrival ? convertUnix(arrival, time_zone, "sv-SE") : undefined) : [undefined];
    scipItem.scac = "";
    scipItem.shipmentId = [];
    scipItem.ocrVendor = "N";
    scipItem.timeZone = time_zone;
    scipItem.expectedUnitSource = 'SCIP'
    // We don't want to display 0 or below forecasts
    if (scipItem.expectedUnits > 0) {
      // Don't display po pending schedule after noon on the same day
      if (convertUnixDate(expectedArrivalTime, time_zone) === convertUnixDate(Date.now(), time_zone)
          && convertUnixToLocalHour(Date.now(), time_zone) >= 12) {

      } else {
      // This is for debugging
      scipItem.logicSource = 'SCIP DR Summary';
      incomingIncomingShipments.push(scipItem);
    }
    }
  }

  incomingIncomingShipments = incomingIncomingShipments.sort((a, b) => {
    if (Math.min(...a.scheduledArrivals) > Math.min(...b.scheduledArrivals)) {
      return 1
    }
    if (Math.min(...b.scheduledArrivals) > Math.min(...a.scheduledArrivals)) {
      return -1
    }
    return 0
  });

  incomingDockReceived = incomingDockReceived.sort((a, b) => {
    if (a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000
        > b.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000) {
      return -1
    }
    if (a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000
        < b.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000) {
      return 1
    }
    return 0
  });

  // Add some sortation before we return
  return  ({
    parsedIncomingShipments: incomingIncomingShipments,
    parsedDockReceived: incomingDockReceived,
    parsedDockReceiveSimple: incomingSimpleDockReceived,
  })
}

export { parseShipments };