import PropTypes from "prop-types";

import Column from "@amzn/meridian/column";
import SearchField from "@amzn/meridian/search-field"
import {useState} from "react";


export const ShipmentSearchBar = props => {
    const {searchInput, setSearchInput, getSearchData, setSearchResults} = props;
    const [disabled, setDisabled] = useState(false);

    return (
        <Column>
            <SearchField
                value={searchInput}
                onChange={setSearchInput}
                onSubmit={() => {
                    setDisabled(true);
                    try {
                        getSearchData()
                    } catch (error) {
                        console.log(error);
                        setSearchResults([]);
                    }
                    setDisabled(false);
                }}
                searchButton={true}
                width={360}
                disabled={disabled}
            />
        </Column>
    );
};

ShipmentSearchBar.propTypes = {
    searchInput: PropTypes.string,
    setSearchInput: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
};
