function groupSearchedShipments(searchData: Array<any>, shipmentSummaries: Array<any>) {
    let groupedShipments = {};
    for (const searchItem of searchData) {
        const uniqueKey = `${searchItem.orderId}_${searchItem.palletId}_${searchItem.asin}`;

        if (groupedShipments[uniqueKey]) {
            const groupedShipment = groupedShipments[uniqueKey]
            groupedShipment.confirmedQuantity += searchItem.confirmedQuantity;
            groupedShipment.quantityConfirmed += searchItem.quantityConfirmed;
            groupedShipment.quantityReceived += searchItem.quantityReceived;
            groupedShipment.quantitySerenity += searchItem.quantitySerenity;
            groupedShipment.wmsQuantityReceived += searchItem.wmsQuantityReceived;
            groupedShipment.wmsQuantityReceivedStow += searchItem.wmsQuantityReceivedStow;
            groupedShipment.manifestQuantity += searchItem.manifestQuantity;
            groupedShipments[uniqueKey] = groupedShipment;
        } else {
            const summaryItem = shipmentSummaries.filter(summary => summary.orderId = searchItem.orderId)[0];
            groupedShipments[uniqueKey] = {...summaryItem, ...searchItem};
            groupedShipments[uniqueKey].shipmentType = searchItem.orderId.split("_")[0];
            groupedShipments[uniqueKey].source = groupedShipments[uniqueKey].shipmentType === 'PO' ? summaryItem.distributorId : summaryItem.srcWarehouseId;
            groupedShipments[uniqueKey].arrivalDatetime = summaryItem.arrivalDatetime ?
                summaryItem.arrivalDatetime: summaryItem.vrCheckinCompletionTime ?
                    summaryItem.vrCheckinCompletionTime: summaryItem.freightActualArrivalTime ?
                        summaryItem.freightActualArrivalTime: undefined;
        }
        groupedShipments[uniqueKey].orderId = searchItem.orderId.replace('Trans_', '').replace('PO_', '');

        groupedShipments[uniqueKey].confirmedUnits = groupedShipments[uniqueKey].shipmentType === 'PO' ?
            groupedShipments[uniqueKey].quantityConfirmed : groupedShipments[uniqueKey].manifestQuantity;

        groupedShipments[uniqueKey].receivedUnits = groupedShipments[uniqueKey].shipmentType === 'PO' ?
            groupedShipments[uniqueKey].quantityReceived : groupedShipments[uniqueKey].wmsQuantityReceivedStow;
        groupedShipments[uniqueKey].nyrNys = Math.max(groupedShipments[uniqueKey].confirmedUnits - groupedShipments[uniqueKey].receivedUnits, 0);

    }
    return Object.values(groupedShipments);
}

export { groupSearchedShipments };
