/**
 * A single row in the dock received shipments table. Shows details for a single shipment.
 */

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import Tooltip from "@amzn/meridian/tooltip"
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import dockReceivedPropType from "../../prop-types/dock-received";
import useDockReceivedViewPreferences from "../../hooks/use-dock-received-view-preferences";
import { convertUnix } from "../../utilities/convert-unix";
import ExceptionTag from "../app/exception-tag";
import ReconcileModal from "./reconcile-modal";
import SuppressModal from "./suppress-modal";
import RiverModal from "../river/river-modal";
import useWarehouseData from "../../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import DockReceivedSlaCounter from "./dock-received-sla-counter";
import NYRModal from "./nyr/nyr-modal";
import NYSModal from "./nys/nys-modal";
import ReviewPalletModal from "./review-pallet-modal";
import {loadIdDisplayLength, slaShipmentProgressThreshold} from "../Constants";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import EventIcon from "../event/event-icon";

function findNotificationStatus(notifications, dockReceived) {
    let activeNotification = "";
    for (const [notification, shipments] of Object.entries(notifications)) {
      for (const index in shipments) {
          if (dockReceived === shipments[index]) {
              activeNotification = notification
          }
      }
  }
    return activeNotification
  }


const DockReceivedTableRow = props => {
  const { dockReceived, notifications, warehouseId, dcEscalations, getDCEscalations } = props
  const { business_type, region_id, time_zone, wms } = getWarehouseAttributes(
      {warehouseId: warehouseId, warehouseParams: useWarehouseData()}
  );

  const [viewPreferences] = useDockReceivedViewPreferences()
  const [expandRow, setExpandrow] = useState(false)
  const [exception, setException ] = useState("")
  const visibleColumns = viewPreferences.get("columns")

  const [toasts, setToasts] = useState([]);
  const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id));
  const onOpenToast = loadId => setToasts(toasts.concat({ id: loadId, timeout: 1500 }));

  const onTick = useCallback(
    total => {
      if (total.total <= 0) {
        setException("pastSLA")
      }
      // 2 hours for near sla
      else if (total.total <= (2 * 60 * 60 * 1000)) {
          setException("nearSLA")
      }
      else {
          setException("")
      }
    },
    [setException]
  )
  useEffect(() => {
    setException(findNotificationStatus(notifications, dockReceived));
  }, [notifications, dockReceived, setException]);

  const transshipmentLinkMap = {
    1:
    {
      "AFTLite": `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=${dockReceived.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    },
    2:{
      "AFTLite": `https://aftlite-portal-eu.amazon.com/receive_inventory/show?transferId=${dockReceived.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    },
    3:{
      "AFTLite": `https://aftlite-portal-nrt.amazon.co.jp/receive_inventory/show?transferId=${dockReceived.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    },
  };

  const vendorLinkMap = {

    1:{ 
      "AFTLite": `https://procurementportal-na.corp.amazon.com/bp/po?poId=${dockReceived.loadId}&tabId=summary`,
      "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`
    },
    2:{
      "AFTLite": `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${dockReceived.loadId}&tabId=summary`,
      "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`
    },
    3:{
      "AFTLite": `https://procurementportal-fe.corp.amazon.com/bp/po?poId=${dockReceived.loadId}&tabId=summary`,
      "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`
    },
  }

  // Map to correct workflow based on site parameters
  // TODO: See if we can plug in parameters
  const workflowMap = {
    1: {
      "Mendel": [
        {
          "title": "Item Not on PO/No",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=7a230ad5-e43b-40ce-8f47-e8d0d942ae3d&id=7a230ad5-e43b-40ce-8f47-e8d0d942ae3d"
        },
        {
          "title": "Overage/ Shortage",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=e77d12c1-14af-40f1-b37c-4d33eb254091&id=e77d12c1-14af-40f1-b37c-4d33eb254091"
        },
        {
          "title": "Damaged Products",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=d6c3e259-fc36-4cd6-9047-3a6718b799bd&id=d6c3e259-fc36-4cd6-9047-3a6718b799bd"
        },
        {
          "title": "Expired/ Pad Time at Receive",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=623a6b9b-bcc9-4cc2-b5db-d47d139a7cc1&id=623a6b9b-bcc9-4cc2-b5db-d47d139a7cc1"
        },
        {
          "title": "Other",
          "url": "https://river.amazon.com/?org=amznmndl&buildingId=SEA53&q0=d4775f0b-b726-407d-94db-64e703c3a8cb"
        }
      ],
      "Malone": [
        {
          "title": "Item Not on PO/No",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=7a230ad5-e43b-40ce-8f47-e8d0d942ae3d&id=7a230ad5-e43b-40ce-8f47-e8d0d942ae3d"
        },
        {
          "title": "Overage/ Shortage",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=e77d12c1-14af-40f1-b37c-4d33eb254091&id=e77d12c1-14af-40f1-b37c-4d33eb254091"
        },
        {
          "title": "Damaged Products",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=d6c3e259-fc36-4cd6-9047-3a6718b799bd&id=d6c3e259-fc36-4cd6-9047-3a6718b799bd"
        },
        {
          "title": "Expired/ Pad Time at Receive",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=623a6b9b-bcc9-4cc2-b5db-d47d139a7cc1&id=623a6b9b-bcc9-4cc2-b5db-d47d139a7cc1"
        },
        {
          "title": "Other",
          "url": "https://river.amazon.com/?org=amznmndl&buildingId=SEA53&q0=d4775f0b-b726-407d-94db-64e703c3a8cb"
        }
      ],
      "UFF": [
        {
          "title": "Vendor/ WholeFoods Compliance",
          "url": "https://river.amazon.com/BOS21/workflows?buildingType=UFFSCExecution&q0=d167d18f-8a95-4bfc-9b87-83f15a87e7a5&id=d167d18f-8a95-4bfc-9b87-83f15a87e7a5"
        },
        {
         "title": "Other",
         "url": "https://river.amazon.com/BOS21/home?buildingType=UFFSCExecution"
        }
      ]
    },
    2: {
      "UFF": [
        {
         "title": "Other",
         "url": "https://river.amazon.com/LHR16/home?buildingType=aft_lite_fc&q0=ce9abbea-c39e-4860-bc14-0154f653554b&q1=4962b4d4-23f5-4bdc-afd3-0bbff7f750a8"
        }
      ]
    },
    //needs to be changed need a new river workflow for region 3
    3: {
      "UFF": [
        {
         "title": "Other",
         "url": "https://river.amazon.com/LHR16/home?buildingType=aft_lite_fc&q0=ce9abbea-c39e-4860-bc14-0154f653554b&q1=4962b4d4-23f5-4bdc-afd3-0bbff7f750a8"
        }
      ]
    }

  }

  const riverWorkflows = workflowMap[region_id][business_type] ? workflowMap[region_id][business_type] : [];
  return (
    <TableRow key={dockReceived.id} rowComponents={props.rowComponents}>
      <Toaster toasts={toasts} onCloseToast={onCloseToast}>
        {toast => (
          <Alert toast={true} onClose={toast.onClose}>
            {`${toast.id} copied to clipboard`}
          </Alert>
        )}
      </Toaster>
      {visibleColumns.loadType && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.loadType}
          </Text>
        </TableCell>
      )}
      {visibleColumns.source && (
        <TableCell alignmentHorizontal="center">
          {dockReceived.loadType === 'Vendor' && dockReceived.distributorName ?
              <Tooltip position="end" title={dockReceived.distributorName}>
                <Text tag="p">
                  {dockReceived.source}
                </Text>
              </Tooltip>
              :
              <Text tag="p">
                {dockReceived.source}
              </Text>
          }
        </TableCell>
      )}
      {visibleColumns.loadId && (
        <TableCell alignmentHorizontal="center" width={"10%"}>
          <Row alignmentHorizontal="center">
            <Tooltip
                position="end"
                title={dockReceived.loadId}
                id={`${dockReceived.id}-${dockReceived.loadId}-toolTip`}
            >
            <Link
                href={dockReceived.loadType === "Vendor" ? vendorLinkMap[region_id][wms] : transshipmentLinkMap[region_id][wms]}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Text truncate={true}>{dockReceived.loadId.length > loadIdDisplayLength ? `${dockReceived.loadId.substring(0, loadIdDisplayLength)}...` : dockReceived.loadId}</Text>
            </Link>
            </Tooltip>
            <Button type="icon" size="small" onClick={() => {
              navigator.clipboard.writeText(dockReceived.loadId)
              onOpenToast(dockReceived.loadId)
            }
            }>
              <Icon tokens={copyTokens} />
            </Button>
          </Row>
          { dockReceived.criticalAsinEvents &&
            <Row alignmentHorizontal="center">
              {dockReceived.criticalAsinEvents.map(criticalEvent => <EventIcon event={criticalEvent.eventName} regionId={region_id}/>)}
            </Row>
          }
          {dockReceived.suppression ?
            <ExceptionTag exception={"suppressed"} />
            : null
          }
        
        </TableCell>
      )}
      {visibleColumns.tempZone && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.tempZone}
          </Text>
        </TableCell>
      )}
      {visibleColumns.criticalAsinEvents && (
        <TableCell alignmentHorizontal="center">
          <Column>
            {dockReceived.criticalAsinEvents && dockReceived.criticalAsinEvents.map(criticalEvent => <Text tag="p">{criticalEvent.eventName}</Text> )}
          </Column>
        </TableCell>
      )}
      {visibleColumns.actualArrival && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.actualArrival ? convertUnix(dockReceived.actualArrival, time_zone) : ""}
          </Text>
        </TableCell>
      )}
      {visibleColumns.dockReceived && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.dockReceived ? convertUnix(dockReceived.dockReceived, time_zone) : ""}
          </Text>
        </TableCell>
      )}
      {visibleColumns.lastDockReceiveBy && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.lastDockReceiveBy}
          </Text>
        </TableCell>
      )}
      {visibleColumns.firstReceiveTime && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.firstReceiveTime ? convertUnix(dockReceived.firstReceiveTime, time_zone) : ""}
          </Text>
        </TableCell>
      )}
      {visibleColumns.confirmedUnits && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{dockReceived.confirmedUnits.toLocaleString()}</Text>
        </TableCell>
      )}
      {visibleColumns.asrsConfirmedUnits && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{dockReceived.asrsConfirmedUnits.toLocaleString()}</Text>
        </TableCell>
      )}
      {visibleColumns.asrsConfirmedUnitsPercent && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{`${dockReceived.asrsConfirmedUnitsPercent.toLocaleString()}%`}</Text>
        </TableCell>
      )}
      {visibleColumns.receivedUnits && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{dockReceived.receivedUnits.toLocaleString()}</Text>
        </TableCell>
      )}
      {visibleColumns.asrsReceivedUnits && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{dockReceived.asrsReceivedUnits.toLocaleString()}</Text>
        </TableCell>
      )}
      {visibleColumns.nyrnys && (
        <TableCell alignmentHorizontal="center">
            {dockReceived.nyrnys > 0 ?
                dockReceived.loadType === "Vendor" ?
                  <NYRModal
                    nyr={dockReceived.nyrnys}
                    po={dockReceived.loadId}
                    tempZone={dockReceived.tempZone}
                    criticalAsinEvents={dockReceived.criticalAsinEvents}
                    region_id={region_id}
                  />
                  : <NYSModal
                      nys={dockReceived.nyrnys}
                      manifestId={dockReceived.loadId}
                      tempZone={dockReceived.tempZone}
                      criticalAsinEvents={dockReceived.criticalAsinEvents}
                      region_id={region_id}
                    />

                : <Text tag="p">{dockReceived.nyrnys.toLocaleString()}</Text>
            }
        </TableCell>
      )}
      {visibleColumns.progress && (
        <TableCell alignmentHorizontal="center">
          <Row>
          <Text tag="p">
            {`${dockReceived.progress}%`}
          </Text>
          {dockReceived.palletSummary &&
              <Text>{`(${dockReceived.palletSummary.filter(shipment => shipment.received).length}/${dockReceived.palletSummary.length})`}</Text>
          }
          </Row>
        </TableCell>
      )}
      {visibleColumns.dwellDays && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {dockReceived.dwellDays}
          </Text>
        </TableCell>
      )}
      {visibleColumns.timeToSLA && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            <DockReceivedSlaCounter
                countdownDate={dockReceived.dockReceived + ((dockReceived.sla ? Math.min(dockReceived.sla , 24) : 24) * 60 * 60 * 1000)}
                onTick={onTick}
            />

            {dockReceived.progress < slaShipmentProgressThreshold && <ExceptionTag exception={exception}/>}
          </Text>
        </TableCell>
      )}
      {visibleColumns.action && (
      <TableCell alignmentHorizontal="center">
        
        <Button
          type="icon"
          size="small"
          onClick={() => {
              expandRow ? setExpandrow(false) : setExpandrow(true)
          }}
        >
          {expandRow ? (
            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
            ) : (
              <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
            )
          }
        </Button>
        {expandRow && (
          <Column spacing="300">
            <ReconcileModal
              dockReceived={dockReceived}
              warehouseId={warehouseId}
            />
            { dockReceived.loadType !== "Vendor"  && (
            <ReviewPalletModal
              dockReceived={dockReceived}
              warehouseId={warehouseId}
              dcEscalations={dcEscalations}
              getDCEscalations={getDCEscalations}
            />
            )}
            <SuppressModal
              dockReceived={dockReceived}
              warehouseId={warehouseId}
            />
            { region_id !==3 && (
            <RiverModal
              buttonTitle={"River"}
              data={dockReceived}
              modalTitle={"River"}
              riverWorkflows={riverWorkflows}
            />
            )}
          </Column>
        )}
      
      </TableCell>
      )}
    </TableRow>
  )
}

DockReceivedTableRow.propTypes = {
  dockReceived: dockReceivedPropType.isRequired,
  notifications: PropTypes.object,
  rowComponents: TableRow.propTypes.rowComponents,
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func,
}

export default DockReceivedTableRow
